interface IconProps {
  color?: string;
}

export const GameIcon = ({ color = "white" }: IconProps) => (
  <svg width="17" height="12" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M.667 3.728 4.06.087h2.558l1.148 2.106L9.727.087h2.463l4.477 8.21-3.368 3.614h-2.6L9.567 9.833 7.63 11.91H5.127L.667 3.728Zm5.836 6.295L3.249 4.055l1.974-2.118 3.255 5.97-1.975 2.116Zm5.608.065L8.857 4.12l1.973-2.118 3.255 5.97-1.974 2.115Z"
      fill={color}
    ></path>
  </svg>
);

export const SparksIcon = (props: { color: string }) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      d="M7.78754 0.498203L7.68348 1.00732C7.64198 1.21033 7.62123 1.31184 7.57094 1.39353C7.52649 1.46572 7.46572 1.52649 7.39353 1.57094C7.31184 1.62124 7.21033 1.64198 7.00732 1.68348L6.4982 1.78754C6.19977 1.84853 6.05056 1.87903 6.01982 1.92943C5.99339 1.97276 5.99339 2.02724 6.01982 2.07057C6.05056 2.12097 6.19977 2.15147 6.4982 2.21246L7.00732 2.31652C7.21033 2.35802 7.31184 2.37876 7.39353 2.42906C7.46572 2.47351 7.52649 2.53428 7.57094 2.60647C7.62124 2.68816 7.64198 2.78967 7.68348 2.99268L7.78754 3.5018C7.84853 3.80022 7.87903 3.94944 7.92943 3.98018C7.97276 4.00661 8.02724 4.00661 8.07057 3.98018C8.12097 3.94944 8.15147 3.80023 8.21246 3.50182L8.31652 2.99268C8.35802 2.78967 8.37877 2.68816 8.42906 2.60647C8.47351 2.53428 8.53428 2.47351 8.60647 2.42906C8.68816 2.37877 8.78967 2.35802 8.99267 2.31653L9.5018 2.21246C9.80023 2.15147 9.94944 2.12097 9.98018 2.07057C10.0066 2.02724 10.0066 1.97276 9.98018 1.92943C9.94944 1.87903 9.80023 1.84853 9.5018 1.78754L8.99268 1.68348C8.78967 1.64198 8.68816 1.62124 8.60647 1.57094C8.53428 1.52649 8.47351 1.46572 8.42906 1.39353C8.37877 1.31184 8.35802 1.21034 8.31653 1.00733L8.21246 0.498204C8.15147 0.199788 8.12097 0.0505597 8.07057 0.0198236C8.02724 -0.00660786 7.97276 -0.00660786 7.92943 0.0198236C7.87903 0.0505602 7.84853 0.199778 7.78754 0.498203Z"
      fill={props.color}
    />
    <path
      d="M2.6813 3.7473C2.7728 3.29966 2.81855 3.07584 2.89414 3.02974C2.95914 2.99009 3.04085 2.99009 3.10586 3.02974C3.18145 3.07584 3.2272 3.29966 3.3187 3.74731L3.47479 4.51099C3.53703 4.8155 3.56815 4.96776 3.64359 5.09029C3.71027 5.19859 3.80141 5.28973 3.90971 5.35641C4.03224 5.43185 4.18449 5.46297 4.48899 5.52521L5.2527 5.6813C5.70034 5.7728 5.92416 5.81855 5.97026 5.89414C6.00991 5.95915 6.00991 6.04085 5.97026 6.10586C5.92416 6.18145 5.70034 6.2272 5.25269 6.3187L4.48901 6.47479C4.1845 6.53703 4.03224 6.56815 3.90971 6.64359C3.80141 6.71027 3.71027 6.80141 3.64359 6.90971C3.56815 7.03224 3.53703 7.1845 3.47479 7.48901L3.3187 8.2527C3.2272 8.70034 3.18145 8.92416 3.10586 8.97026C3.04085 9.00991 2.95914 9.00991 2.89414 8.97026C2.81855 8.92416 2.7728 8.70034 2.6813 8.25269L2.52521 7.48901C2.46297 7.1845 2.43185 7.03224 2.35641 6.90971C2.28973 6.80141 2.19859 6.71027 2.09029 6.64359C1.96776 6.56815 1.8155 6.53703 1.51099 6.47479L0.747305 6.3187C0.299662 6.2272 0.0758405 6.18145 0.0297354 6.10586C-0.00991178 6.04085 -0.00991179 5.95915 0.0297354 5.89414C0.0758403 5.81855 0.299661 5.7728 0.747301 5.6813L1.51099 5.52521C1.8155 5.46297 1.96776 5.43185 2.09029 5.35641C2.19858 5.28973 2.28973 5.19859 2.35641 5.09029C2.43185 4.96776 2.46297 4.8155 2.52521 4.51099L2.6813 3.7473Z"
      fill={props.color}
    />
    <path
      d="M9.36261 5.49461C9.5456 4.59932 9.63709 4.15168 9.78828 4.05947C9.91829 3.98018 10.0817 3.98018 10.2117 4.05947C10.3629 4.15168 10.4544 4.59932 10.6374 5.49461L10.9496 7.02197C11.0741 7.631 11.1363 7.93551 11.2872 8.18058C11.4205 8.39717 11.6028 8.57946 11.8194 8.71282C12.0645 8.8637 12.369 8.92594 12.978 9.05042L14.5054 9.36261C15.4007 9.5456 15.8483 9.63709 15.9405 9.78828C16.0198 9.91829 16.0198 10.0817 15.9405 10.2117C15.8483 10.3629 15.4007 10.4544 14.5054 10.6374L12.978 10.9496C12.369 11.0741 12.0645 11.1363 11.8194 11.2872C11.6028 11.4205 11.4205 11.6028 11.2872 11.8194C11.1363 12.0645 11.0741 12.369 10.9496 12.978L10.6374 14.5054C10.4544 15.4007 10.3629 15.8483 10.2117 15.9405C10.0817 16.0198 9.91829 16.0198 9.78828 15.9405C9.63709 15.8483 9.5456 15.4007 9.36261 14.5054L9.05043 12.978C8.92595 12.369 8.86371 12.0645 8.71282 11.8194C8.57946 11.6028 8.39717 11.4205 8.18058 11.2872C7.93551 11.1363 7.631 11.0741 7.02197 10.9496L5.49461 10.6374C4.59932 10.4544 4.15168 10.3629 4.05947 10.2117C3.98018 10.0817 3.98018 9.91829 4.05947 9.78828C4.15168 9.63709 4.59932 9.5456 5.4946 9.36261L7.02197 9.05043C7.631 8.92595 7.93551 8.86371 8.18058 8.71282C8.39717 8.57946 8.57946 8.39717 8.71282 8.18058C8.8637 7.93552 8.92594 7.63102 9.05042 7.02202L9.36261 5.49461Z"
      fill={props.color}
    />
  </svg>
);

export const FriendsIcon = (props: { color: string }) => (
  <svg width="17" height="13" viewBox="0 0 17 13" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.38413 0C3.55151 0 2.08853 1.51187 2.08853 3.34867C2.08853 5.18547 3.55151 6.69733 5.38413 6.69733C7.21675 6.69733 8.67973 5.18547 8.67973 3.34867C8.67973 1.51187 7.21675 0 5.38413 0ZM5.38413 7.72413C2.96231 7.72413 0.942937 9.4128 0.372239 11.6789C0.0798095 12.8399 10.7455 13.0662 10.396 11.6789C9.82533 9.4128 7.80591 7.72413 5.38413 7.72413ZM11.8672 12.4853C11.6729 12.4796 11.5141 12.3322 11.4759 12.1416C11.4196 11.8602 11.3501 11.6215 11.3176 11.5099L11.3176 11.5099C11.3094 11.4817 11.3036 11.4617 11.3009 11.4511C11.1277 10.7631 10.8402 10.1192 10.4595 9.54249C10.3284 9.34387 10.3778 9.06981 10.5934 8.96898C11.1152 8.72492 11.6964 8.5886 12.3101 8.5886C14.2365 8.5886 15.8429 9.93184 16.2968 11.7344C16.4462 12.3274 14.0657 12.5497 11.8672 12.4853ZM9.68868 5.10817C9.68868 3.6471 10.8524 2.4445 12.3101 2.4445C13.7679 2.4445 14.9316 3.6471 14.9316 5.10817C14.9316 6.56924 13.7679 7.77184 12.3101 7.77184C10.8524 7.77184 9.68868 6.56924 9.68868 5.10817Z"
      fill={props.color}
    />
  </svg>
);

export const StepsIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Icon left">
      <g id="shape">
        <path
          d="M7.06959 3.73452C7.25642 4.75923 7.14806 6.19887 6.92667 7.06153C6.87801 7.20812 6.8451 7.31718 6.82015 7.3999C6.75502 7.61576 6.744 7.65229 6.64838 7.70832C6.62984 7.71918 6.62056 7.72461 6.60486 7.73197C6.59271 7.73765 6.57466 7.74479 6.56191 7.74895C6.54543 7.75433 6.52975 7.75788 6.4984 7.765C5.77463 7.92927 5.05722 8.1085 4.33 8.29247C4.23957 8.31535 4.19435 8.32679 4.1307 8.32112C4.08738 8.31726 4.00879 8.29182 3.9714 8.26954C3.91646 8.23682 3.89783 8.2146 3.86058 8.17016C3.09517 7.25716 2.50523 4.45527 2.61279 3.68394C2.66603 2.18717 2.92853 0.224873 5.02195 0.532096C6.00373 0.78874 6.72863 1.86964 7.06959 3.73452Z"
          fill="white"
        />
        <path
          d="M10.9781 4.02489C13.0715 3.71767 13.3349 5.67996 13.3872 7.1758C13.4957 7.94724 12.9048 10.7506 12.1391 11.6626C12.102 11.7069 12.0834 11.729 12.0285 11.7617C11.9912 11.7839 11.9127 11.8093 11.8694 11.8132C11.8059 11.8188 11.7608 11.8075 11.6706 11.7847C10.9431 11.6013 10.2255 11.422 9.50215 11.2569C9.47099 11.2498 9.45539 11.2462 9.43904 11.2409C9.42636 11.2367 9.40853 11.2297 9.39644 11.224C9.38085 11.2167 9.37154 11.2113 9.35292 11.2004C9.25635 11.144 9.24533 11.1076 9.17908 10.8887C9.15415 10.8063 9.12141 10.6982 9.07335 10.5534C8.8529 9.69072 8.7436 8.25202 8.93043 7.22638C9.27233 5.36243 9.99722 4.28153 10.9781 4.02489Z"
          fill="white"
        />
        <path
          d="M9.12078 11.9035C9.05848 11.9508 9.02154 12.0164 8.94764 12.1476C7.04006 15.535 11.0546 17.2087 11.6802 13.0059C11.7038 12.8476 11.7156 12.7685 11.6927 12.6893C11.6737 12.6234 11.6298 12.5542 11.5782 12.5092C11.5162 12.455 11.4345 12.4308 11.2712 12.3823C11.0477 12.3161 10.8384 12.2556 10.6375 12.1975C10.2855 12.0958 9.95959 12.0016 9.63033 11.8975C9.46834 11.8464 9.38732 11.8207 9.30959 11.8288C9.24391 11.8357 9.17342 11.8635 9.12078 11.9035Z"
          fill="white"
        />
        <path
          d="M4.7285 8.89038C4.56532 8.9388 4.48374 8.96301 4.42175 9.0172C4.37021 9.06226 4.32625 9.13141 4.30728 9.19728C4.28445 9.27649 4.29622 9.35557 4.31976 9.51373C4.94524 13.7169 8.96 12.0424 7.0524 8.65572C6.9785 8.52453 6.94155 8.45893 6.87927 8.41164C6.82663 8.37168 6.75618 8.3438 6.69051 8.33697C6.61279 8.32888 6.53178 8.35447 6.36976 8.40564C6.0441 8.50848 5.72191 8.60157 5.37426 8.70202C5.16979 8.7611 4.9565 8.82272 4.7285 8.89038Z"
          fill="white"
        />
      </g>
    </g>
  </svg>
);

export const HeartIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="15"
    viewBox="0 0 16 15"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.333252 5.15448C0.333252 2.71301 2.2931 0.803345 4.75992 0.803345C5.9536 0.803345 7.10911 1.24336 7.96686 2.01263C7.99963 2.04203 8.0002 2.04203 8.03298 2.01263C8.89072 1.24336 10.0462 0.803345 11.2399 0.803345C13.7067 0.803345 15.6666 2.71301 15.6666 5.15448C15.6666 7.19771 14.4945 8.94941 13.0706 10.4341C12.067 11.4807 10.8822 12.4477 9.8205 13.3143C9.38211 13.6722 8.96469 14.0129 8.58964 14.335C8.47327 14.435 8.35576 14.5347 8.20529 14.5784C8.06973 14.6179 7.92911 14.6176 7.79367 14.5778C7.64328 14.5336 7.52607 14.4335 7.40995 14.3331C7.02318 13.9988 6.59032 13.6448 6.13543 13.2728C5.08655 12.415 3.92047 11.4613 2.93178 10.4326C1.50709 8.95015 0.333252 7.20111 0.333252 5.15448Z"
      fill="#FF0D75"
    />
  </svg>
);

export const PlusIcon = () => (
  <svg
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Plus">
      <path
        id="shape"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.69999 1.7C9.69999 1.03726 9.16273 0.5 8.49999 0.5C7.83725 0.5 7.29999 1.03726 7.29999 1.7V6.79995H2.2C1.53726 6.79995 1 7.33721 1 7.99995C1 8.66269 1.53726 9.19995 2.2 9.19995H7.29999V14.3C7.29999 14.9627 7.83725 15.5 8.49999 15.5C9.16273 15.5 9.69999 14.9627 9.69999 14.3V9.19995H14.8C15.4627 9.19995 16 8.66269 16 7.99995C16 7.33721 15.4627 6.79995 14.8 6.79995H9.69999V1.7Z"
        fill="white"
      />
    </g>
  </svg>
);

export const CopyIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Copy">
      <g id="shape">
        <path
          d="M3.84042 0.833252H7.75966C8.19103 0.833247 8.54303 0.833243 8.82892 0.856601C9.12458 0.880758 9.39041 0.932204 9.63829 1.05851C10.0272 1.25664 10.3433 1.5728 10.5415 1.96167C10.6678 2.20955 10.7192 2.47538 10.7434 2.77104C10.7667 3.05693 10.7667 3.40892 10.7667 3.84029C10.7667 3.89568 10.7667 3.92337 10.764 3.94666C10.7423 4.13055 10.5973 4.27556 10.4134 4.29719C10.3902 4.29993 10.3625 4.29993 10.3071 4.29993H7.3071C6.87572 4.29992 6.52373 4.29992 6.23784 4.32328C5.94217 4.34743 5.67635 4.39888 5.42847 4.52518C5.0396 4.72332 4.72344 5.03948 4.5253 5.42835C4.399 5.67623 4.34755 5.94205 4.3234 6.23772C4.30004 6.52361 4.30004 6.8756 4.30005 7.30698V10.307C4.30005 10.3623 4.30005 10.39 4.29731 10.4133C4.27568 10.5972 4.13067 10.7422 3.94678 10.7638C3.92349 10.7666 3.8958 10.7666 3.84042 10.7666C3.40904 10.7666 3.05705 10.7666 2.77116 10.7432C2.4755 10.7191 2.20968 10.6676 1.96179 10.5413C1.57293 10.3432 1.25677 10.027 1.05863 9.63817C0.932326 9.39029 0.88088 9.12446 0.856723 8.8288C0.833365 8.54291 0.833369 8.19093 0.833374 7.75955V3.8403C0.833369 3.40893 0.833365 3.05693 0.856723 2.77104C0.88088 2.47538 0.932326 2.20955 1.05863 1.96167C1.25677 1.5728 1.57293 1.25664 1.96179 1.05851C2.20968 0.932204 2.4755 0.880758 2.77116 0.856601C3.05705 0.833243 3.40905 0.833247 3.84042 0.833252Z"
          fill="white"
        />
        <path
          d="M12.1596 5.23326H8.24037C7.80899 5.23325 7.457 5.23325 7.17111 5.25661C6.87544 5.28076 6.60962 5.33221 6.36174 5.45851C5.97287 5.65665 5.65671 5.97281 5.45857 6.36168C5.33227 6.60956 5.28082 6.87538 5.25667 7.17104C5.23331 7.45694 5.23331 7.80892 5.23332 8.2403V12.1595C5.23331 12.5909 5.23331 12.9429 5.25667 13.2288C5.28082 13.5245 5.33227 13.7903 5.45857 14.0382C5.65671 14.427 5.97287 14.7432 6.36174 14.9413C6.60962 15.0676 6.87544 15.1191 7.17111 15.1432C7.457 15.1666 7.80898 15.1666 8.24036 15.1666H12.1596C12.591 15.1666 12.943 15.1666 13.2289 15.1432C13.5245 15.1191 13.7903 15.0676 14.0382 14.9413C14.4271 14.7432 14.7433 14.427 14.9414 14.0382C15.0677 13.7903 15.1191 13.5245 15.1433 13.2288C15.1667 12.9429 15.1667 12.5909 15.1667 12.1595V8.2403C15.1667 7.80892 15.1667 7.45694 15.1433 7.17104C15.1191 6.87538 15.0677 6.60956 14.9414 6.36168C14.7433 5.97281 14.4271 5.65665 14.0382 5.45851C13.7903 5.33221 13.5245 5.28076 13.2289 5.25661C12.943 5.23325 12.591 5.23325 12.1596 5.23326Z"
          fill="white"
        />
      </g>
    </g>
  </svg>
);

export const RefreshIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
  >
    <path
      d="M0.755599 6.55884C1.29157 4.55856 2.58896 2.96382 4.25004 2.00479C5.91113 1.04576 7.94091 0.719565 9.94119 1.25554C11.7045 1.72802 13.1527 2.79222 14.1281 4.17566L14.4482 2.98088C14.5483 2.60745 14.9321 2.38584 15.3055 2.4859C15.6789 2.58596 15.9006 2.9698 15.8005 3.34322L15.024 6.241C14.924 6.61443 14.5401 6.83603 14.1667 6.73597L11.2689 5.95952C10.8955 5.85946 10.6739 5.47562 10.774 5.1022C10.874 4.72877 11.2579 4.50716 11.6313 4.60722L12.9728 4.96667C12.1792 3.84833 11.0066 2.9904 9.57884 2.60783C7.95164 2.17183 6.30274 2.43625 4.95004 3.21723C3.59735 3.9982 2.5439 5.29398 2.1079 6.92118C2.00784 7.29461 1.624 7.51622 1.25057 7.41616C0.877147 7.3161 0.65554 6.93226 0.755599 6.55884Z"
      fill="#0A051C"
    />
    <path
      d="M15.2445 10.4412C14.7085 12.4415 13.4111 14.0363 11.75 14.9953C10.0889 15.9543 8.05914 16.2805 6.05887 15.7445C4.29553 15.2721 2.84735 14.2079 1.872 12.8244L1.55186 14.0192C1.4518 14.3926 1.06796 14.6142 0.694537 14.5142C0.321111 14.4141 0.0995035 14.0303 0.199563 13.6569L0.97602 10.7591C1.07608 10.3857 1.45991 10.164 1.83334 10.2641L4.73112 11.0406C5.10455 11.1406 5.32615 11.5245 5.22609 11.8979C5.12603 12.2713 4.7422 12.4929 4.36877 12.3929L3.02728 12.0334C3.82085 13.1517 4.99345 14.0097 6.42122 14.3922C8.04842 14.8283 9.69732 14.5638 11.05 13.7829C12.4027 13.0019 13.4562 11.7061 13.8922 10.0789C13.9922 9.70547 14.3761 9.48386 14.7495 9.58392C15.1229 9.68398 15.3445 10.0678 15.2445 10.4412Z"
      fill="#0A051C"
    />
  </svg>
);

export const CheckmarkIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.4657 3.4403C15.8514 3.83559 15.8437 4.4687 15.4484 4.85441L6.64392 13.4453C6.25548 13.8243 5.63564 13.8243 5.24719 13.4453L0.551646 8.86392C0.156347 8.47823 0.148558 7.84512 0.534248 7.44982C0.919939 7.05452 1.55306 7.04673 1.94835 7.43242L5.94553 11.3324L14.0516 3.42294C14.4469 3.03724 15.08 3.04501 15.4657 3.4403Z"
      fill="white"
      fillOpacity="0.6"
    />
  </svg>
);
