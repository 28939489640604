export const UpIcon = () => (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Icon right">
      <g id="shape" filter="url(#filter0_dd_5000_692)">
        <path
          d="M13.0339 7.27623C13.4302 6.71796 13.6284 6.43883 13.8744 6.34016C14.0898 6.2538 14.3301 6.2538 14.5454 6.34016C14.7915 6.43883 14.9897 6.71796 15.386 7.27623L23.392 18.5538C23.9146 19.2899 24.1759 19.658 24.1639 19.9647C24.1534 20.2318 24.025 20.4804 23.8133 20.6435C23.5701 20.8309 23.1187 20.8309 22.2159 20.8309H6.20395C5.30116 20.8309 4.84977 20.8309 4.6066 20.6435C4.39488 20.4804 4.26646 20.2318 4.25601 19.9647C4.24401 19.658 4.50531 19.2899 5.02791 18.5538L13.0339 7.27623Z"
          fill="#DFFFFD"
        />
      </g>
    </g>
    <defs>
      <filter
        id="filter0_dd_5000_692"
        x="1.66407"
        y="0.303559"
        width="25.0918"
        height="20.5273"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="-3.38028" />
        <feGaussianBlur stdDeviation="1.29577" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_5000_692"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="-1.12676" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.325 0 0 0 0 0 0 0 0 0 0.2275 0 0 0 0.5 0"
        />
        <feBlend
          mode="normal"
          in2="effect1_dropShadow_5000_692"
          result="effect2_dropShadow_5000_692"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect2_dropShadow_5000_692"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

export const DownIcon = () => (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Icon right">
      <g id="shape" filter="url(#filter0_dd_5000_696)">
        <path
          d="M13.0339 20.6813C13.4302 21.2396 13.6284 21.5187 13.8744 21.6174C14.0898 21.7037 14.3301 21.7037 14.5454 21.6174C14.7915 21.5187 14.9897 21.2396 15.386 20.6813L23.392 9.40377C23.9146 8.66761 24.1759 8.29954 24.1639 7.99281C24.1534 7.72575 24.025 7.47709 23.8133 7.31398C23.5701 7.12663 23.1187 7.12664 22.2159 7.12664H6.20395C5.30116 7.12664 4.84977 7.12663 4.6066 7.31398C4.39488 7.47709 4.26646 7.72575 4.25601 7.99281C4.24401 8.29954 4.50531 8.66761 5.02791 9.40377L13.0339 20.6813Z"
          fill="#DFFFFD"
        />
      </g>
    </g>
    <defs>
      <filter
        id="filter0_dd_5000_696"
        x="1.66407"
        y="1.15488"
        width="25.0918"
        height="20.5273"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="-3.38028" />
        <feGaussianBlur stdDeviation="1.29577" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_5000_696"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="-1.12676" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.325 0 0 0 0 0 0 0 0 0 0.2275 0 0 0 0.5 0"
        />
        <feBlend
          mode="normal"
          in2="effect1_dropShadow_5000_696"
          result="effect2_dropShadow_5000_696"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect2_dropShadow_5000_696"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

export const LeftIcon = () => (
  <svg
    width="28"
    height="30"
    viewBox="0 0 28 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Icon right">
      <g id="shape" filter="url(#filter0_dd_5000_686)">
        <path
          d="M7.10729 15.3015C6.54902 15.6978 6.26988 15.896 6.17121 16.142C6.08486 16.3573 6.08486 16.5977 6.17121 16.813C6.26988 17.0591 6.54902 17.2572 7.10728 17.6536L18.3848 25.6595C19.121 26.1821 19.489 26.4434 19.7958 26.4314C20.0628 26.421 20.3115 26.2926 20.4746 26.0809C20.6619 25.8377 20.6619 25.3863 20.6619 24.4835L20.6619 8.47153C20.6619 7.56874 20.6619 7.11734 20.4746 6.87418C20.3115 6.66246 20.0628 6.53404 19.7958 6.52359C19.489 6.51159 19.121 6.77289 18.3848 7.29549L7.10729 15.3015Z"
          fill="#DFFFFD"
        />
      </g>
    </g>
    <defs>
      <filter
        id="filter0_dd_5000_686"
        x="3.5149"
        y="0.551362"
        width="19.7385"
        height="25.8805"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="-3.38028" />
        <feGaussianBlur stdDeviation="1.29577" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_5000_686"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="-1.12676" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.325 0 0 0 0 0 0 0 0 0 0.2275 0 0 0 0.5 0"
        />
        <feBlend
          mode="normal"
          in2="effect1_dropShadow_5000_686"
          result="effect2_dropShadow_5000_686"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect2_dropShadow_5000_686"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

export const RightIcon = () => (
  <svg
    width="28"
    height="30"
    viewBox="0 0 28 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Icon right">
      <g id="shape" filter="url(#filter0_dd_5000_702)">
        <path
          d="M21.3153 17.6563C21.8736 17.26 22.1527 17.0618 22.2514 16.8158C22.3377 16.6004 22.3377 16.3601 22.2514 16.1447C22.1527 15.8987 21.8736 15.7005 21.3153 15.3042L10.0378 7.29822C9.30165 6.77562 8.93357 6.51432 8.62684 6.52632C8.35978 6.53677 8.11112 6.66519 7.94801 6.87691C7.76067 7.12008 7.76067 7.57147 7.76067 8.47426L7.76067 24.4862C7.76067 25.389 7.76067 25.8404 7.94801 26.0836C8.11112 26.2953 8.35978 26.4237 8.62684 26.4342C8.93357 26.4462 9.30165 26.1849 10.0378 25.6623L21.3153 17.6563Z"
          fill="#DFFFFD"
        />
      </g>
    </g>
    <defs>
      <filter
        id="filter0_dd_5000_702"
        x="5.16919"
        y="0.554048"
        width="19.7385"
        height="25.8805"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="-3.38028" />
        <feGaussianBlur stdDeviation="1.29577" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_5000_702"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="-1.12676" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.325 0 0 0 0 0 0 0 0 0 0.2275 0 0 0 0.5 0"
        />
        <feBlend
          mode="normal"
          in2="effect1_dropShadow_5000_702"
          result="effect2_dropShadow_5000_702"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect2_dropShadow_5000_702"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

// not an icon lol
export const ControlsBackground = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    style={{ position: "absolute", bottom: 0 }}
    viewBox="0 0 375 276"
    fill="none"
  >
    <g filter="url(#filter0_dii_5002_537)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M265 80C265 68.799 265 63.1984 262.82 58.9202C260.903 55.1569 257.843 52.0973 254.08 50.1799C249.802 48 244.201 48 233 48L142 48C130.799 48 125.198 48 120.92 50.1799C117.157 52.0973 114.097 55.1569 112.18 58.9202C110 63.1984 110 68.799 110 80V81C110 89.2843 103.284 96 95 96L32 96C20.799 96 15.1984 96 10.9202 98.1799C7.15695 100.097 4.09734 103.157 2.17987 106.92C0 111.198 0 116.799 0 128L0 277C0 288.201 0 293.802 2.17987 298.08C4.09734 301.843 7.15695 304.903 10.9202 306.82C15.1984 309 20.799 309 32 309L343 309C354.201 309 359.802 309 364.08 306.82C367.843 304.903 370.903 301.843 372.82 298.08C375 293.802 375 288.201 375 277V128C375 116.799 375 111.198 372.82 106.92C370.903 103.157 367.843 100.097 364.08 98.1799C359.802 96 354.201 96 343 96L280 96C271.716 96 265 89.2843 265 81V80Z"
        fill="url(#paint0_linear_5002_537)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M265 80C265 68.799 265 63.1984 262.82 58.9202C260.903 55.1569 257.843 52.0973 254.08 50.1799C249.802 48 244.201 48 233 48L142 48C130.799 48 125.198 48 120.92 50.1799C117.157 52.0973 114.097 55.1569 112.18 58.9202C110 63.1984 110 68.799 110 80V81C110 89.2843 103.284 96 95 96L32 96C20.799 96 15.1984 96 10.9202 98.1799C7.15695 100.097 4.09734 103.157 2.17987 106.92C0 111.198 0 116.799 0 128L0 277C0 288.201 0 293.802 2.17987 298.08C4.09734 301.843 7.15695 304.903 10.9202 306.82C15.1984 309 20.799 309 32 309L343 309C354.201 309 359.802 309 364.08 306.82C367.843 304.903 370.903 301.843 372.82 298.08C375 293.802 375 288.201 375 277V128C375 116.799 375 111.198 372.82 106.92C370.903 103.157 367.843 100.097 364.08 98.1799C359.802 96 354.201 96 343 96L280 96C271.716 96 265 89.2843 265 81V80Z"
        fill="url(#paint1_radial_5002_537)"
        fillOpacity="0.2"
        style={{ mixBlendMode: "plus-lighter" }}
      />
    </g>
    <defs>
      <filter
        id="filter0_dii_5002_537"
        x="-40"
        y="0"
        width="455"
        height="341"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="-8" />
        <feGaussianBlur stdDeviation="20" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_5002_537"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_5002_537"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="3.9" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 0.125 0 0 0 0 0.7375 0 0 0 0.3 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect2_innerShadow_5002_537"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="2" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 0.125 0 0 0 0 0.7375 0 0 0 0.3 0"
        />
        <feBlend
          mode="normal"
          in2="effect2_innerShadow_5002_537"
          result="effect3_innerShadow_5002_537"
        />
      </filter>
      <linearGradient
        id="paint0_linear_5002_537"
        x1="187.5"
        y1="48.0001"
        x2="187.5"
        y2="276"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#420B50" />
        <stop offset="1" stopColor="#19033A" />
      </linearGradient>
      <radialGradient
        id="paint1_radial_5002_537"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(180.48 15.736) rotate(90) scale(162.396 166.458)"
      >
        <stop stopColor="#FF0051" />
        <stop offset="1" stopColor="#FFB2CB" stopOpacity="0" />
      </radialGradient>
    </defs>
  </svg>
);
