let canvas: HTMLCanvasElement;
let cols: number;
let rows: number;
let gridSize = 32;

let snake: Snake;
let foods: Food[] = [];
let activeLeg: Direction.Left | Direction.Right;

export interface GameState {
  isGameOver: boolean;
  score: number;
}

interface Game {
  controls: (direction: Direction) => void;
  resetState: () => void;
  restart: () => void;
}

const gameState: GameState = { isGameOver: false, score: 0 };

let loopHandler: Timer;

const external = {
  stateUpdate: (_: GameState) => {},
};

const headImage = new Image(100, 100);
headImage.src = "./games/snake/public/head.png";

const leftLegImage = new Image(90, 118);
leftLegImage.src = "./games/snake/public/leg-left.png";
const rightLegImage = new Image(90, 118);
rightLegImage.src = "./games/snake/public/leg-right.png";

const leftLegActiveImage = new Image(300, 300);
leftLegActiveImage.src = "./games/snake/public/leg-left-active.png";
const rightLegActiveImage = new Image(300, 300);
rightLegActiveImage.src = "./games/snake/public/leg-right-active.png";

// tail

const tailTipImage = new Image(100, 100);
tailTipImage.src = "./games/snake/public/tail-tip.png";

const tailStraightImage = new Image(100, 100);
tailStraightImage.src = "./games/snake/public/tail-straight.png";

const tailBottomLeftAngleImage = new Image(100, 100);
tailBottomLeftAngleImage.src =
  "./games/snake/public/tail-bottom-left-angle.png";

const tailBottomRightAngleImage = new Image(100, 100);
tailBottomRightAngleImage.src =
  "./games/snake/public/tail-bottom-right-angle.png";

const tailTopLeftAngleImage = new Image(100, 100);
tailTopLeftAngleImage.src = "./games/snake/public/tail-top-left-angle.png";

const tailTopRightAngleImage = new Image(100, 100);
tailTopRightAngleImage.src = "./games/snake/public/tail-top-right-angle.png";

function draw(ctx: CanvasRenderingContext2D) {
  ctx.clearRect(0, 0, canvas.width, canvas.height);

  drawGrid(ctx);
  for (let i = 0; i < foods.length; i++) {
    foods[i].draw(ctx);
  }
}

function drawGrid(ctx: CanvasRenderingContext2D) {
  // could be initialized only once matey
  const gradient = ctx.createRadialGradient(
    ctx.canvas.width / 2,
    ctx.canvas.height / 2,
    ctx.canvas.width / 2,
    ctx.canvas.width / 2,
    ctx.canvas.height / 2,
    gridSize / 2
  );
  gradient.addColorStop(0, "rgba(44, 59, 101, 1)");
  gradient.addColorStop(1, "rgba(88, 99, 114, 1)");

  ctx.fillStyle = gradient;

  for (let i = 0; i < cols; i++) {
    ctx.fillRect(i * gridSize, 0, 1, canvas.height);
  }
  for (let i = 0; i < rows; i++) {
    ctx.fillRect(0, i * gridSize, canvas.width, 1);
  }

  ctx.fillStyle = "rgba(255, 70, 147, 1)";
  ctx.fillRect(0, 4 * gridSize, canvas.width, 1);
  ctx.fillRect(0, (4 + 16) * gridSize, canvas.width, 1);
  ctx.fillRect(0, 4 * gridSize, 1, 16 * gridSize);
  ctx.fillRect(canvas.width - 1, 4 * gridSize, 1, 16 * gridSize);
}

class Food {
  constructor(
    public x: number,
    public y: number,
    public leg: Direction.Left | Direction.Right = Math.random() < 0.5
      ? Direction.Left
      : Direction.Right
  ) {}

  draw(ctx: CanvasRenderingContext2D) {
    if (activeLeg == null || this.leg === activeLeg) {
      ctx.drawImage(
        this.leg === Direction.Left ? leftLegActiveImage : rightLegActiveImage,
        this.x - gridSize,
        this.y - gridSize,
        gridSize * 3,
        gridSize * 3
      );
    } else {
      ctx.drawImage(
        this.leg === Direction.Left ? leftLegImage : rightLegImage,
        this.x,
        this.y - 3,
        gridSize,
        gridSize / 0.8
      );
    }
  }
}

class SnakePart {
  public direction?: Direction;

  constructor(
    public x: number,
    public y: number,
    public snake: Snake,
    public leg?: Direction.Left | Direction.Right,
    public prevPart?: SnakePart,
    public nextPart?: SnakePart
  ) {
    this.direction = this.snake.direction;
  }

  draw(ctx: CanvasRenderingContext2D) {
    const isHead = this.prevPart == null;

    if (isHead) {
      let angle = 0;

      switch (this.snake.direction) {
        case Direction.Up:
          angle = Math.PI;
          break;
        case Direction.Down:
          angle = 0;
          break;
        case Direction.Left:
          angle = Math.PI / 2;
          break;
        case Direction.Right:
          angle = -Math.PI / 2;
          break;
      }

      ctx.save();
      ctx.translate(this.x + gridSize / 2, this.y + gridSize / 2);
      ctx.rotate(angle);
      ctx.translate(-(this.x + gridSize / 2), -(this.y + gridSize / 2));
      ctx.drawImage(
        headImage,
        this.x - gridSize / 3.2,
        this.y - gridSize / 2,
        gridSize * 1.6,
        gridSize * 2
      );
      ctx.restore();
    } else {
      let image: HTMLImageElement;
      const isTip = this.nextPart == null;

      if (isTip) {
        image = tailTipImage;
      } else {
        image = tailStraightImage;
      }

      const angle = this.direction != this.nextPart?.direction;
      if (angle) {
        if (
          (this.direction === Direction.Up &&
            this.nextPart?.direction === Direction.Left) ||
          (this.direction === Direction.Right &&
            this.nextPart?.direction === Direction.Down)
        ) {
          image = tailTopRightAngleImage;
          ctx.drawImage(image, this.x, this.y, gridSize, gridSize);
          return;
        } else if (
          (this.direction === Direction.Up &&
            this.nextPart?.direction === Direction.Right) ||
          (this.direction === Direction.Left &&
            this.nextPart?.direction === Direction.Down)
        ) {
          image = tailTopLeftAngleImage;
          ctx.drawImage(image, this.x, this.y, gridSize, gridSize);
          return;
        } else if (
          (this.direction === Direction.Down &&
            this.nextPart?.direction === Direction.Left) ||
          (this.direction === Direction.Right &&
            this.nextPart?.direction === Direction.Up)
        ) {
          image = tailBottomRightAngleImage;
          ctx.drawImage(image, this.x, this.y - 1, gridSize, gridSize + 2);
          return;
        } else if (
          (this.direction === Direction.Down &&
            this.nextPart?.direction === Direction.Right) ||
          (this.direction === Direction.Left &&
            this.nextPart?.direction === Direction.Up)
        ) {
          image = tailBottomLeftAngleImage;
          ctx.drawImage(image, this.x, this.y, gridSize, gridSize);
          return;
        }
      }

      ctx.save();
      ctx.translate(this.x + gridSize / 2, this.y + gridSize / 2);
      switch (this.direction) {
        case Direction.Up:
          ctx.rotate(Math.PI / 2);
          break;
        case Direction.Down:
          ctx.rotate(-Math.PI / 2);
          break;
        case Direction.Left:
          ctx.rotate(0);
          break;
        case Direction.Right:
          ctx.rotate(Math.PI);
          break;
      }
      ctx.translate(-(this.x + gridSize / 2), -(this.y + gridSize / 2));
      ctx.drawImage(image, this.x - 1, this.y, gridSize + 2, gridSize);
      ctx.restore();
    }
  }
}

function spawnFood() {
  const randomX = Math.floor(Math.random() * cols) * gridSize;
  const randomY = Math.floor(Math.random() * 16) * gridSize + 4 * gridSize;

  if (
    snake.hasTailAt(randomX, randomY) ||
    foods.some((f) => f.x === randomX && f.y === randomY)
  ) {
    console.log("Recalc!");
    spawnFood();
    return;
  }

  foods.push(new Food(randomX, randomY));
}

export enum Direction {
  Up,
  Down,
  Left,
  Right,
}

class Snake {
  parts: SnakePart[] = [];
  head: SnakePart;
  direction: Direction = Direction.Down;

  constructor(public x: number, public y: number) {
    this.head = new SnakePart(x, y, this);
    this.parts.push(this.head);
    // as per Silviu request
    // TODO: make a method for this
    const startingTail = new SnakePart(x, y, this);
    startingTail.prevPart = this.head;
    this.parts.push(startingTail);
  }

  move(direction: Direction) {
    if (
      (this.direction === Direction.Down && direction === Direction.Up) ||
      (this.direction === Direction.Up && direction === Direction.Down) ||
      (this.direction === Direction.Left && direction === Direction.Right) ||
      (this.direction === Direction.Right && direction === Direction.Left)
    ) {
      return;
    }

    this.direction = direction;
    this.head.direction = direction;
  }

  draw(ctx: CanvasRenderingContext2D) {
    for (let i = this.parts.length - 1; i >= 0; i--) {
      this.parts[i].draw(ctx);
    }
  }

  update() {
    let headPos = { x: this.head.x, y: this.head.y };

    // move the snake head
    switch (this.direction) {
      case Direction.Up:
        headPos.y -= gridSize;
        break;
      case Direction.Down:
        headPos.y += gridSize;
        break;
      case Direction.Left:
        headPos.x -= gridSize;
        break;
      case Direction.Right:
        headPos.x += gridSize;
        break;
    }

    // check for collision
    foods.forEach((f, idx) => {
      if (f.x === headPos.x && f.y === headPos.y) {
        console.log("nom nom");
        foods.splice(idx, 1);
        activeLeg = f.leg === Direction.Left ? Direction.Right : Direction.Left;

        const lastPart = this.parts[this.parts.length - 1];
        const lastLeg = lastPart.leg;
        if (lastLeg == f.leg) {
          clearInterval(loopHandler);
          gameState.isGameOver = true;
          external.stateUpdate(gameState);
          console.log("Game over, wrong leg");
        } else {
          gameState.score++;
          external.stateUpdate(gameState);

          const nextPart = new SnakePart(
            headPos.x,
            headPos.y,
            this,
            f.leg,
            lastPart
          );
          lastPart.nextPart = nextPart;
          this.parts.push(nextPart);
        }
      }
    });

    // snake the snake parts!
    for (let i = this.parts.length - 1; i > 0; i--) {
      this.parts[i].x = this.parts[i - 1].x;
      this.parts[i].y = this.parts[i - 1].y;
      this.parts[i].direction = this.parts[i - 1].direction;
    }

    this.head.x = headPos.x;
    this.head.y = headPos.y;

    // check if head collides with other parts
    this.parts.forEach((part) => {
      if (part === this.head) return;

      if (part.x === this.head.x && part.y === this.head.y) {
        clearInterval(loopHandler);
        gameState.isGameOver = true;
        external.stateUpdate(gameState);
        console.log("Game over");
      }
    });

    // check for game over
    if (
      this.head.y <= 3 * gridSize ||
      this.head.y >= (3 + 17) * gridSize ||
      this.head.x < 0 ||
      this.head.x >= canvas.width
    ) {
      clearInterval(loopHandler);
      gameState.isGameOver = true;
      external.stateUpdate(gameState);
      console.log("Game over");
    }
  }

  hasTailAt(x: number, y: number) {
    return this.parts.some((part) => {
      return part.x === x && part.y === y;
    });
  }
}

export function load(stateUpdate: (state: GameState) => void): Game {
  external.stateUpdate = stateUpdate;

  canvas = document.getElementById("game-canvas") as HTMLCanvasElement;
  canvas.width = window.innerWidth;
  canvas.height = window.innerHeight;

  gridSize = canvas.width / 16;
  cols = canvas.width / gridSize;
  rows = canvas.height / gridSize;

  snake = new Snake(8 * gridSize, 4 * gridSize);
  foods = [];

  window.addEventListener(
    "keydown",
    function (e) {
      if (
        ["Space", "ArrowUp", "ArrowDown", "ArrowLeft", "ArrowRight"].indexOf(
          e.code
        ) > -1
      ) {
        e.preventDefault();
      }
    },
    false
  );

  document.addEventListener("keydown", (e) => {
    switch (e.key) {
      case "ArrowUp":
        snake.move(Direction.Up);
        break;
      case "ArrowDown":
        snake.move(Direction.Down);
        break;
      case "ArrowLeft":
        snake.move(Direction.Left);
        break;
      case "ArrowRight":
        snake.move(Direction.Right);
        break;
      case "Space":
        clearInterval(loopHandler);
        break;
    }
  });

  let ctx = canvas.getContext("2d");

  if (ctx == null) {
    throw new Error("Could not get 2d context");
  }

  //????
  ctx.imageSmoothingEnabled = true;

  let tick = 0;
  const loop = () => {
    if (tick === 0 || tick % 20 === 0) {
      spawnFood();
    }

    draw(ctx!);
    snake.update();
    snake.draw(ctx!);

    tick++;
  };
  draw(ctx);

  setTimeout(() => {
    loopHandler = setInterval(loop, 150);
  }, 2000);

  const resetState = () => {
    tick = 0;
    snake = new Snake(8 * gridSize, 4 * gridSize);
    foods = [];
    gameState.isGameOver = false;
    gameState.score = 0;
    external.stateUpdate(gameState);
  };

  return {
    controls: (direction: Direction) => {
      snake.move(direction);
    },
    restart: () => {
      resetState();

      clearInterval(loopHandler);
      loopHandler = setInterval(loop, 150);
    },
    resetState,
  };
}
