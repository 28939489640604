import styled from "styled-components";

import { StepsIcon, GameIcon } from "../icons";

interface Friend {
  name: string;
  avatar: string;
  steps: number;
  sweat: number;
}

interface Props {
  index: number;
  model: Friend;
}

export default function FriendCell(props: Props) {
  return (
    <Container isFirst={props.index === 1}>
      <MainBlock>
        <Place>{props.index}</Place>
        <Profile>
          <Avatar src={props.model.avatar + `?index=${props.index}`} />
          <Name>{props.model.name}</Name>
        </Profile>
      </MainBlock>
      <Amount>
        <Steps>
          <StepsIcon />
          {Intl.NumberFormat().format(props.model.steps)}
        </Steps>
        <Sweat>
          <GameIcon color="rgba(255, 255, 255, 0.3)" />
          {Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "SWC",
            currencyDisplay: "code",
          })
            .format(props.model.sweat)
            .replace("SWC", "")}
        </Sweat>
      </Amount>
    </Container>
  );
}

const Container = styled.div<{ isFirst: boolean }>`
  display: flex;
  flex: 1;
  padding: 0.5rem 1rem;
  justify-content: center;
  align-items: center;
  gap: 0.75rem;

  border-radius: 1rem;
  border: ${(props) =>
    props.isFirst
      ? "0.5px solid #ffc918"
      : "1px solid rgba(255, 255, 255, 0.1)"};

  background: ${(props) =>
    props.isFirst
      ? "linear-gradient( 264deg, rgba(255, 251, 135, 0.3) 0%, rgba(255, 255, 255, 0) 100%)"
      : "background: linear-gradient( 264deg, rgba(255, 255, 255, 0.15) 0%, rgba(255, 255, 255, 0) 100%)"};
`;

const MainBlock = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  gap: 0.375rem;
`;

const Place = styled.p`
  font-family: Inter;
  font-size: 1rem;
  font-style: italic;
  font-weight: 900;
  line-height: 1.375rem; /* 137.5% */
  letter-spacing: -0.002rem;
  color: white;
  min-width: 1.5rem;
`;

const Profile = styled.div`
  display: flex;
  align-items: center;
  gap: 0.625rem;
`;

const Avatar = styled.img`
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  border: 1px solid white;
`;

const Name = styled.div`
  font-family: Inter;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1rem; /* 133.333% */

  color: white;
`;

const Amount = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const Steps = styled.div`
  font-family: Inter;
  font-size: 0.9375rem;
  font-style: italic;
  font-weight: 900;
  line-height: 1.375rem; /* 146.667% */
  letter-spacing: -0.00188rem;

  color: white;
`;

const Sweat = styled.div`
  font-family: Inter;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1rem; /* 133.333% */
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.3);
`;
