import { useEffect } from "react";
import axios, { AxiosInstance } from "axios";
import { useAtom, getDefaultStore } from "jotai";

import { TelegramAccount, Player, telegramAccountAtom } from "./state/account";

interface ValidateInitDataResponse {
  data: {
    auth_token: string;
    telegram_account: TelegramAccount;
  };
}

interface CompleteGameResponse {
  data: {
    player: Player;
    point_reward: { points_amount: number; redeemed_at?: string };
  };
}

export class Api {
  private apiClient: AxiosInstance;
  static instance = new Api();

  constructor() {
    this.apiClient = axios.create({
      baseURL: "https://api.sweateconomy.com/wallet/api/v1/telegram",
      headers: {
        common: {
          "Content-Type": "application/json",
        },
        "Telegram-Auth-Token": window.localStorage.getItem("auth_token"),
      },
    });
  }

  public setAuthToken(authToken: string): void {
    window.localStorage.setItem("auth_token", authToken);
    window.localStorage.setItem("auth_ts", Date.now().toString());

    this.apiClient.defaults.headers["Telegram-Auth-Token"] = authToken;
  }

  public async validateInitData(
    initData: string
  ): Promise<ValidateInitDataResponse | undefined> {
    try {
      const response = await this.apiClient.post<ValidateInitDataResponse>(
        "/mini_app/validate_init_data.json",
        { init_data: initData }
      );

      this.setAuthToken(response.data.data.auth_token);

      return response.data;
    } catch (error: any) {
      handleError(error);
    }
  }

  public async linkAccount(): Promise<void> {
    try {
      const response = await this.apiClient.post<any>(
        "/account_linking/initiate.json",
        { linking_direction: "telegram_to_sweat" }
      );

      return response.data;
    } catch (error: any) {
      handleError(error);
    }
  }

  public async completeAccountLinking(token: string): Promise<void> {
    try {
      const response = await this.apiClient.post<any>(
        "/account_linking/complete.json",
        { token, linking_direction: "sweat_to_telegram" }
      );

      console.log("Linking response", { response });

      return response.data;
    } catch (error: any) {
      handleError(error);
    }
  }

  // Game
  public async startGame(): Promise<void> {
    try {
      const response = await this.apiClient.post<any>("/games/start.json");

      console.log("Start game response", { response });

      return response.data;
    } catch (error: any) {
      handleError(error);
    }
  }

  public async completeGame(
    achievedPoints: number,
    duration: number
  ): Promise<void> {
    try {
      const response = await this.apiClient.post<CompleteGameResponse>(
        "/games/complete.json",
        {
          achieved_points: achievedPoints,
          duration,
        }
      );
      console.log("Complete game response", { response });

      const store = getDefaultStore();
      const account = store.get(telegramAccountAtom);

      // oooh, ugly
      if (account != null) {
        const pointsToAdd = response.data.data.point_reward.points_amount;

        store.set(telegramAccountAtom, {
          ...account,
          total_points: account.total_points + pointsToAdd,
          player: response.data.data.player,
        });
      }

      return;
    } catch (error: any) {
      handleError(error);
    }
  }
}

function handleError(error: any) {
  if (axios.isAxiosError(error) && error.response) {
    if (error.response.status === 422) {
      throw new Error(
        "Validation Error: " +
          (error.response.data.message || "Unprocessable Entity")
      );
    }
  } else if (error.response.status === 404) {
    console.error("I forgive you, but CORS IS PITA...");
  } else {
    throw new Error(
      "Network Error: " + (error.message || "Something went wrong")
    );
  }
}

export function useAuth() {
  const [_, setTelegramAccount] = useAtom(telegramAccountAtom);

  useEffect(() => {
    const data = window.Telegram.WebApp.initData;
    console.log("Data", { data });

    const authTS = window.localStorage.getItem("auth_ts");
    if (authTS) {
      const authDate = new Date(parseInt(authTS));

      if (Date.now() - authDate.getTime() > 30 * 60 * 1000) {
        init().then(() => {
          checkCompletingAccount();
        });
      } else {
        checkCompletingAccount();
      }
    } else {
      init().then(() => {
        checkCompletingAccount();
      });
    }

    async function checkCompletingAccount() {
      const token = window.Telegram.WebApp.initDataUnsafe.start_param;
      //TODO: urlencode or prefix?
      const isCompletionToken = true;

      if (isCompletionToken && token) {
        console.log("Token from start_app", { token });
        const response = await Api.instance.completeAccountLinking(token);
        console.log("Complete Account response", { response });
      }
    }

    async function init() {
      const response = await Api.instance.validateInitData(data);
      if (response != null && response.data.telegram_account != null) {
        setTelegramAccount(response.data.telegram_account);
      }

      console.log("validate response", { response });

      // const linkResponse = await api.linkAccount();
      // console.log({ linkResponse });
    }
  }, []);
}
