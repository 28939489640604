import { useState, useEffect } from "react";
import styled from "styled-components";

import FriendCell from "./common/FriendCell";

export default function Friends() {
  return (
    <Container>
      <Header>
        <Title>SCOREBOARD?</Title>
        <Hint>EARNED STEPS</Hint>
      </Header>
      <List>
        {[
          {
            name: "John Doe",
            avatar: "https://i.pravatar.cc/32",
            steps: 50_000,
            sweat: 100,
          },
          {
            name: "John Doe",
            avatar: "https://i.pravatar.cc/32",
            steps: 50_000,
            sweat: 100,
          },
          {
            name: "John Doe",
            avatar: "https://i.pravatar.cc/32",
            steps: 50_000,
            sweat: 100,
          },
          {
            name: "John Doe",
            avatar: "https://i.pravatar.cc/32",
            steps: 50_000,
            sweat: 100,
          },
          {
            name: "John Doe",
            avatar: "https://i.pravatar.cc/32",
            steps: 50_000,
            sweat: 100,
          },
          {
            name: "John Doe",
            avatar: "https://i.pravatar.cc/32",
            steps: 50_000,
            sweat: 100,
          },
          {
            name: "John Doe",
            avatar: "https://i.pravatar.cc/32",
            steps: 50_000,
            sweat: 100,
          },
          {
            name: "John Doe",
            avatar: "https://i.pravatar.cc/32",
            steps: 50_000,
            sweat: 100,
          },
          {
            name: "John Doe",
            avatar: "https://i.pravatar.cc/32",
            steps: 50_000,
            sweat: 100,
          },
          {
            name: "John Doe",
            avatar: "https://i.pravatar.cc/32",
            steps: 50_000,
            sweat: 100,
          },
          {
            name: "John Doe",
            avatar: "https://i.pravatar.cc/32",
            steps: 50_000,
            sweat: 100,
          },
        ].map((friend, index) => (
          <FriendCell key={index} index={index + 1} model={friend} />
        ))}
      </List>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1.5rem 0rem 1.5rem;
`;

const List = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0rem 1.7rem;
  gap: 0.5rem;
`;

const Title = styled.h1`
  text-shadow: 0px -2px 58.6px rgba(255, 113, 191, 0.57),
    0px 4px 4px rgba(0, 0, 0, 0.25);
  font-family: Inter;
  font-size: 1.125rem;
  font-style: italic;
  font-weight: 800;
  line-height: 2.75rem; /* 244.444% */
  letter-spacing: -0.00225rem;
  text-transform: uppercase;

  color: white;
`;

const Hint = styled.p`
  font-family: Inter;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1rem; /* 133.333% */
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.6);
`;

const useLinkingStartApp = () => {
  const [linkToken, setLinkToken] = useState<string>();

  useEffect(() => {
    const data = window.Telegram.WebApp.initDataUnsafe;
    console.log(data);
    setLinkToken(data.start_param);
  }, []);
};
