import { atom } from "jotai";
import { atomWithStorage } from "jotai/utils";

export interface TelegramAccount {
  id: string;
  telegram_id: string;
  username: string;
  first_name: string;
  last_name: string;
  total_points: number;
  total_points_in_sweat: number;
  total_claimed_sweat: number;
  game_high_score: number;
  account: Account;
  player: Player;
}

interface Account {
  id: string;
  account_id: string;
  username?: string;
  activated: boolean;
  avatar?: {
    cover: string;
    preview: string;
  };
  subscription?: {
    id: string;
    name: string;
    price: number;
    premium: boolean;
  };
}

export interface Player {
  lives: number;
  highest_score: number;
  accumulated_score: number;
  next_life_at: string | null;
}

interface Totals {
  points: number;
  sweat: number;
}

export const telegramAccountAtom = atomWithStorage<TelegramAccount | null>(
  "account",
  null
);

export const playerLivesAtom = atom(
  (get) => get(telegramAccountAtom)?.player.lives || 0
);

export const totalsAtom = atom<Totals>((get) => {
  const account = get(telegramAccountAtom);

  if (account) {
    return {
      points: account.total_points,
      sweat: account.total_points_in_sweat,
    };
  }

  return { points: 0, sweat: 0 };
});
