import styled from "styled-components";

export default function ComingSoon() {
  return (
    <Container>
      <Logo />
      <HeadlineContainer>
        <Headline />
      </HeadlineContainer>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  bbackground-color: red;
`;

const Logo = styled.img.attrs({ src: "./public/coming-soon-logo.png" })``;

const HeadlineContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: red;
`;

const Headline = styled.div`
  position: absolute;
  width: 80%;
  height: 12rem;
  top: -9rem;
  background-image: url("./public/coming-soon-headline.svg");
  background-size: cover;
  background-repeat: no-repeat;
`;
