import { useEffect, useState } from "react";
import { createRoot } from "react-dom/client";
import styled from "styled-components";

import { useAuth } from "./api";

import TopBar from "./common/TopBar";
import SnekGame from "./SnekGame";
import PlayNow from "./PlayNow";
import Friends from "./Friends";
import ComingSoon from "./ComingSoon";

import {
  GameIcon,
  SparksIcon,
  FriendsIcon,
  PlusIcon,
  CopyIcon,
  StepsIcon,
} from "./icons";

//TODO: Add localization
//TODO: Add analytics

const App = () => {
  useAuth();
  const [page, setPage] = useState(0);
  const [gameStarted, setGameStarted] = useState(false);
  const [topBarVisible, setTopBarVisible] = useState(true);
  const [navbarVisible, setNavbarVisible] = useState(true);

  useEffect(() => {
    setTopBarVisible(!gameStarted);
    setNavbarVisible(!gameStarted);
  }, [gameStarted]);

  useEffect(() => {
    window.Telegram.WebApp.expand();
  }, []);

  return (
    <Container>
      {topBarVisible ? <TopBar /> : null}
      <Content style={{ overflowY: page == 0 ? "hidden" : "auto" }}>
        {page === 0 ? (
          gameStarted ? (
            <SnekGame onBackPress={handleBackPress} />
          ) : (
            <PlayNow onPlayNowClick={handlePlayNowClick} />
          )
        ) : page === 1 ? (
          <ComingSoon />
        ) : page === 2 ? (
          <Friends />
        ) : null}
      </Content>
      <Footer>
        <Drawer style={{ display: page === 2 ? "flex" : "none" }}>
          <Title>
            Get&nbsp;&nbsp;
            <StepsIcon /> <Bold>100</Bold>&nbsp;&nbsp; for each invited friend
          </Title>
          <Buttons>
            <Button
              className="funky"
              style={{ flex: 1 }}
              onClick={handleInviteClick}
            >
              <PlusIcon />
              Invite friends
            </Button>
            <Button className="funky" onClick={handleCopyClick}>
              <CopyIcon />
            </Button>
          </Buttons>
        </Drawer>
        <Navbar style={{ display: navbarVisible ? "flex" : "none" }}>
          <ButtonGroup>
            <Button
              className={page === 0 ? "important" : "disabled"}
              style={{ flex: 1 }}
              onClick={handleGameClick}
            >
              <GameIcon
                color={
                  page === 0 ? "rgba(10, 5, 28, 1)" : "rgba(255, 255, 255, 1)"
                }
              />
              Game
            </Button>
            <Button
              className={page === 1 ? "important" : "disabled"}
              style={{ flex: 1 }}
              onClick={handleQuestsClick}
            >
              <SparksIcon
                color={
                  page === 1 ? "rgba(10, 5, 28, 1)" : "rgba(255, 255, 255, 1)"
                }
              />
              Earn
            </Button>
            <Button
              className={page === 2 ? "important" : "disabled"}
              style={{ flex: 1 }}
              onClick={handleFriendsClick}
            >
              <FriendsIcon
                color={
                  page === 2 ? "rgba(10, 5, 28, 1)" : "rgba(255, 255, 255, 1)"
                }
              />
              Friends
            </Button>
          </ButtonGroup>
        </Navbar>
      </Footer>
    </Container>
  );

  function handlePlayNowClick() {
    setGameStarted(true);
  }

  function handleBackPress() {
    setGameStarted(false);
  }

  function handleInviteClick() {
    window.open(
      `https://t.me/share/url?text=YO&url=https://t.me/SWOWPOCBot/SWOWPOC?startapp=ref_nvzn`
    );
    // window.open(
    //   "https://wallet.sweateconomy.com/tma/link_account?token=zrJQzY85mAYo0bE73x3Y5Q"
    // );
  }

  function handleCopyClick() {
    navigator.clipboard.writeText(window.Telegram.WebApp.initData);
    // navigator.clipboard.writeText(
    //   "https://t.me/SWOWPOCBot/SWOWPOC?startapp=ref_nvzn"
    // );
  }

  function handleGameClick() {
    setPage(0);
  }

  function handleQuestsClick() {
    setPage(1);
  }

  function handleFriendsClick() {
    setPage(2);
  }
};

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100vh;
  font-family: Inter;
`;

const Title = styled.span`
  font-family: Inter;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.375rem; /* 133.333% */
  color: white;
`;

const Bold = styled.span`
  font-family: Inter;
  font-size: 1rem;
  font-style: italic;
  font-weight: 900;
  line-height: 1.375rem; /* 137.5% */
  letter-spacing: -0.002rem;
  color: white;
`;

const Content = styled.div`
  display: flex;
  flex: 1;
  flex-grow: 1;
  overflow-y: auto;
`;

const ButtonGroup = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-evenly;
  gap: 0.5rem;
`;

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  font-family: Inter;
  font-weight: 700;
  font-size: 1em;
  line-height: 1.2rem;
  border: none;
  border-radius: 0.9rem;
  color: rgba(61, 12, 100, 1);
  padding: 0.9rem 1.2rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Footer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 2.5rem;
`;

const Navbar = styled.div`
  display: flex;
  flex: 1;
  width: 90%;
  padding: 1rem 1.2rem;
  background-color: rgba(255, 255, 255, 0.5);
  background: linear-gradient(0deg, #420b50 0%, #19033a 100%);
  border-top-left-radius: 1.5rem;
  border-top-right-radius: 1.5rem;
  border-color: rgba(255, 87, 160, 1);

  background: linear-gradient(180deg, #420b50 0%, #19033a 100%);
  background: radial-gradient(
    47.42% 71.23% at 48% -14.15%,
    rgba(255, 0, 81, 0.2) 0%,
    rgba(255, 178, 203, 0) 100%
  );

  box-shadow: 0px -8px 40px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 4px 7.8px 0px rgba(255, 32, 188, 0.3) inset;
  box-shadow: 0px 2px 0px 0px rgba(255, 32, 188, 0.3) inset;
`;

const Drawer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.6rem;
  width: 80%;
  padding: 1rem 1.25rem;
  border-radius: 1.25rem 1.25rem 0 0;
  background: linear-gradient(
    264.37deg,
    rgba(47, 43, 63, 0.7) -2.66%,
    rgba(36, 31, 52, 0.7) 93.05%
  );
  border: 2px solid rgba(255, 255, 255, 0.1);
  border-bottom: none;
  box-shadow: 0px -4px 16px 0px rgba(0, 0, 0, 0.16);
`;

const Buttons = styled.div`
  display: flex;
  width: 100%;
  gap: 1rem;
`;

const root = createRoot(document.getElementById("root")!);
root.render(<App />);
